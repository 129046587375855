import React from "react";
import Layout from "../../layouts";

import './style.css'

const PrivacyPolicyDe = () => {
    return (
        <Layout lang="de" showNav={false}>
            <h1>Photo-Histogram-Game – Datenschutz
                <wbr/>
                bestimmungen
            </h1>
            <h2>Verwendete Persönliche Daten</h2>
            <p>
                Sämtliche in der App verwendeten Daten sind nur auf dem Gerät gespeichert. Es findet keine Übertragung
                an irgendwelche Verarbeiter statt.
            </p>

            <h2>Nutzungsstatistiken</h2>
            <p>
                Um die App zu verbessern werden anonymisiert Nutzungsstatistiken erhoben (Spielstart, Spielende).
                Diese Daten werden nicht an Dritte weitergegeben.
            </p>

            <h2>Fragen</h2>
            <p>
                Für Fragen stehe ich unter der E-Mail Adresse galler.stefan@gmail.com zur Verfügung.
            </p>
        </Layout>
    )
}

export default PrivacyPolicyDe
